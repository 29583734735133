<template>
  <div>
    <slot name="ProgressBar"/>
    <page-header
      :title="`촬영은\n어디에서 하나요?`"
    >
    </page-header>

    <div class="ui-border-line ui-h-0 ui-mb-6"></div>

    <tag-box>
      <li
        v-for="(row, key) in playLocation"
        :key="key"
        @click="() => formData.playLocation = row"
        :class="{ on: formData.playLocation === row }"
      >
        {{row}}
      </li>
    </tag-box>

    <div class="ui-border-line ui-h-0 ui-mb-6"></div>

    <footer-box
      submitText="다음"
      :submitCb="() => nextStep()"
      :submitDisabled="!validate"
    >
    </footer-box>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import TagBox from '@/components/common/TagBox'
import FooterBox from '@/components/common/FooterBox'
import Modal from '@/components/common/Modal'

export default {
  components: {
    PageHeader,
    TagBox,
    FooterBox,
    Modal,
  },
  data() {
    const postOffer = this.$store.state.postOffer
    const playLocation = this.$config.constant.playLocation
    return {
      playLocation,
      formData: {
        playLocation: postOffer.playLocation,
      },
    }
  },
  computed: {
    step: {
      get() {
        return this.$route.params.step
      },
    },
    postOffer: {
      get() {
        return this.$store.state.postOffer
      },
      set(val) {
        this.$store.commit('setPostOffer', val)
      },
    },
    validate: {
      get() {
        return !this.formData.playLocation === false
      },
    },
  },
  beforeCreate() {
    if (!this.$store.state.postOffer.genre) this.$router.replace('/offer/post/0')
  },
  methods: {
    nextStep() {
      const playLocation = this.formData.playLocation
      this.postOffer = { playLocation }
      this.$parent.nextStep()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("ProgressBar"),
      _c("page-header", { attrs: { title: "촬영은\n어디에서 하나요?" } }),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-6" }),
      _c(
        "tag-box",
        _vm._l(_vm.playLocation, function(row, key) {
          return _c(
            "li",
            {
              key: key,
              class: { on: _vm.formData.playLocation === row },
              on: {
                click: function() {
                  return (_vm.formData.playLocation = row)
                }
              }
            },
            [_vm._v(" " + _vm._s(row) + " ")]
          )
        }),
        0
      ),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-6" }),
      _c("footer-box", {
        attrs: {
          submitText: "다음",
          submitCb: function() {
            return _vm.nextStep()
          },
          submitDisabled: !_vm.validate
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }